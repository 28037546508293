import React, { Fragment } from 'react';
import { getTagVariant, initializeDate, stringifyDate } from '../../utils';
import { formatMoney } from 'utils/money';
import { ClaimStatus, IClaim, IMailAddress, IPolicyCardHeaderProps } from '../../interfaces';
import {
  ClaimDetailsItem,
  Splitter,
  StyledClaimTag,
  StyledNewClaimTag,
  StatusWrapper,
  StyledIndicatorIcon,
  StyledLabelDiv,
  P2Font,
  StyledBarSpan,
  StyledProgressBarDiv,
  StyledPolicyNumberDiv,
  StyledPolicyNumberAnchor,
  StyledClaimAmountDiv,
  StyledPaidClaimAmountDiv,
  StyledTotalClaimAmountDiv,
  RemainingCoverageWrapper,
  StyledRemainingCoverageSpan,
  StyledInnerProgressSpan
} from './ClaimStyles';

export const isUnpaidClaim = (status: ClaimStatus) => {
  return [
    ClaimStatus.NEW,
    ClaimStatus.PROCESSING,
    ClaimStatus.ACCEPTED,
    ClaimStatus.DENIED,
    ClaimStatus.WITHDRAWN
  ].includes(status);
};

export const getFullMailingAddress = (address: IMailAddress) => {
  if (address.address_line_two !== null && address.address_line_two.length > 0) {
    return `${address.address_line_one}, ${address.address_line_two}, ${address.address_city}, ${address.address_state}, ${address.address_zip}`;
  } else {
    return `${address.address_line_one}, ${address.address_city}, ${address.address_state}, ${address.address_zip}`;
  }
};

export const ClaimTag = ({ status, maskedStatus }: { status: ClaimStatus; maskedStatus: string }) => {
  if (status === ClaimStatus.NEW) {
    return (
      <StyledNewClaimTag variant="state">
        <StatusWrapper>{maskedStatus}</StatusWrapper>
        <StyledIndicatorIcon />
      </StyledNewClaimTag>
    );
  }
  return <StyledClaimTag variant={getTagVariant(status)}>{maskedStatus}</StyledClaimTag>;
};

export const ClaimCardDetails = ({
  status,
  amount_cents,
  total_amount_paid_cents,
  property_owner,
  property,
  policy_holder,
  last_day_to_submit_claim
}: IClaim) => {
  return (
    <>
      {isUnpaidClaim(status) ? (
        <>
          <ClaimDetailsItem>
            <StyledClaimAmountDiv amount_cents={amount_cents}>
              {formatMoney(amount_cents).maskedValue}
            </StyledClaimAmountDiv>
            <StyledLabelDiv>Claim Amount</StyledLabelDiv>
          </ClaimDetailsItem>
          <hr />
          <ClaimDetailsItem>
            <P2Font>{stringifyDate(initializeDate(last_day_to_submit_claim))}</P2Font>
            <StyledLabelDiv>Last day to submit claims</StyledLabelDiv>
          </ClaimDetailsItem>
        </>
      ) : (
        <>
          <ClaimDetailsItem>
            <StyledPaidClaimAmountDiv total_amount_paid_cents={total_amount_paid_cents}>
              {formatMoney(total_amount_paid_cents).maskedValue}
            </StyledPaidClaimAmountDiv>
            <StyledLabelDiv>Amount paid</StyledLabelDiv>
          </ClaimDetailsItem>
          <hr />
          <ClaimDetailsItem>
            <StyledTotalClaimAmountDiv total_amount_paid_cents={total_amount_paid_cents}>
              {formatMoney(amount_cents).maskedValue}
            </StyledTotalClaimAmountDiv>
            <StyledLabelDiv>Claim Amount</StyledLabelDiv>
          </ClaimDetailsItem>
        </>
      )}
      <hr />
      <ClaimDetailsItem>
        <P2Font>{property_owner.name}</P2Font> <StyledLabelDiv>Property Owner</StyledLabelDiv>
      </ClaimDetailsItem>
      <hr />
      <ClaimDetailsItem>
        <P2Font>{policy_holder.name}</P2Font> <StyledLabelDiv>Policy Holder</StyledLabelDiv>
      </ClaimDetailsItem>
      <hr />
      <ClaimDetailsItem>
        <P2Font>{property.full_address}</P2Font> <StyledLabelDiv>Address</StyledLabelDiv>
      </ClaimDetailsItem>
    </>
  );
};

export const getClaimTitle = (claim: IClaim) => {
  if (claim.single_or_garden) {
    return `${claim.renter_full_name} | ${claim.claim_type}`;
  }
  return `${claim.renter_full_name} | ${`${claim.unit_name} -`} ${claim.claim_type}`;
};

export const PolicyCardHeader = ({ policy }: IPolicyCardHeaderProps) => {
    const remainingPolicyCoverage: number = Math.max(0, policy.remaining_coverage_amount_cents);
    const remainingPercentOfCoverage: number = policy
    ? (remainingPolicyCoverage / policy.coverage_amount_cents) * 100
    : 0;
  return (
    <Fragment>
      <ClaimDetailsItem>
        <RemainingCoverageWrapper remainingCoverage={remainingPolicyCoverage}>
          <StyledRemainingCoverageSpan remainingCoverage={remainingPolicyCoverage}>
            {formatMoney(policy ? remainingPolicyCoverage : 0).maskedValue}
          </StyledRemainingCoverageSpan>
          &nbsp;/&nbsp;{formatMoney(policy ? policy.coverage_amount_cents : 0).maskedValue}
        </RemainingCoverageWrapper>
        <StyledLabelDiv>Remaining coverage</StyledLabelDiv>
      </ClaimDetailsItem>
      <ClaimDetailsItem>
        <StyledProgressBarDiv>
          <StyledBarSpan>
            <StyledInnerProgressSpan remainingPercentOfCoverage={remainingPercentOfCoverage} />
          </StyledBarSpan>
        </StyledProgressBarDiv>
      </ClaimDetailsItem>
      <Splitter />
      <ClaimDetailsItem>
        <StyledPolicyNumberAnchor href={`/admin/renter_policies/${policy?.id}`}>
          <StyledPolicyNumberDiv>#{policy?.policy_number}</StyledPolicyNumberDiv>
        </StyledPolicyNumberAnchor>
        <StyledLabelDiv>Policy number</StyledLabelDiv>
      </ClaimDetailsItem>
      <Splitter />
      {policy?.bond_version && (
        <Fragment>
          <ClaimDetailsItem>
            <P2Font>{policy?.bond_version}</P2Font>
            <StyledLabelDiv>Bond Version</StyledLabelDiv>
          </ClaimDetailsItem>
          <Splitter />
        </Fragment>
      )}
      <ClaimDetailsItem>
        <P2Font>{policy?.policy_coverage_dates}</P2Font>
        <StyledLabelDiv>Coverage Dates</StyledLabelDiv>
      </ClaimDetailsItem>
      <Splitter />
      <ClaimDetailsItem>
        <P2Font>{policy?.lease_dates}</P2Font>
        <StyledLabelDiv>Lease Dates</StyledLabelDiv>
      </ClaimDetailsItem>
    </Fragment>
  );
};
