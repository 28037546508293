/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { OverlayProvider } from './utils/overlay';
import { UserContext } from './utils/userRole';
import { ToastContextProvider } from './toast/toast-context';
import RootRoutes from './RootRoutes';
const ReactDOM = require('react-dom');
const queryClient = new QueryClient();

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    analytics: undefined | any;
    Appcues: undefined | any;
  }
}

export const TestComponent = (props) => {
  return (
    <div>
      <Link to="/">dashboard</Link>
      <h1>Hello {props.name}</h1>
    </div>
  );
};

const UserProvider = (props) => {
  const [TFAstate, setTFAstate] = useState(props.value);
  const updateTFArequired = (value) => {
    if (TFAstate.isTfaRequired !== value) setTFAstate({ ...TFAstate, isTfaRequired: value });
  };

  return <UserContext.Provider value={{ ...TFAstate, updateTFArequired }}>{props.children}</UserContext.Provider>;
};

const App = (props) => {
  if (process.env.NODE_ENV !== 'production') {
    const axe = require('react-axe');
    axe(React, ReactDOM, 1000);
  }

  const { user = {} } = props;
  const userState = {
    role: user.role,
    id: user.id,
    email: user.email,
    sessionId: user.session_uuid,
    canSendInvites: props.can_send_invites,
    isClaimAdmin: user.is_claim_admin,
    isAutoApprove: user.is_auto_approve,
    isTfaRequired: props.tfa_required
  };

  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider value={userState}>
        <OverlayProvider>
          <Router>
            <ToastContextProvider position="bottom-left">
              <RootRoutes />
              <ReactQueryDevtools initialIsOpen panelProps={{ style: { backgroundColor: '#99BADD' } }} />
            </ToastContextProvider>
          </Router>
        </OverlayProvider>
      </UserProvider>
    </QueryClientProvider>
  );
};

// @TODO: Move this somewhere test related
export const TestApp = (props) => {
  const { user = {} } = props;
  const userState = {
    role: user.role || 'Administrator',
    id: 1,
    sessionId: '',
    isAutoApprove: false
  };

  return (
    <UserContext.Provider value={userState}>
      {/* <MemoryRouter> */}
      <RootRoutes />
      {/* </MemoryRouter> */}
    </UserContext.Provider>
  );
};

export default App;
