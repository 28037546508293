/** @jsx jsx */
import React, { Fragment, useEffect, useState } from 'react';
import { ActionButton, DropdownMenu } from '@sayrhino/rhino-shared-js';
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import { useParams, Link } from 'react-router-dom';
import { PALETTE, FONTS, MinimizeIcon, LinkIcon, LegacyTag, Loading } from '@sayrhino/rhino-shared-js';
import { useClipboard } from 'use-clipboard-copy';
import useInvitation from '../../../api/v2/useInvitation';
import resendInvitation from '../../../api/v2/resendInvitation';
import { stripTrailingZeroMultiplier, getTagVariant, useReferrer } from './utils/index';
import { useSegmentUser } from './utils/segmentTracker';
import Tooltip from './Tooltip';

const taktileUnderwriting = (window as any).App?.featureFlags?.taktileUnderwriting;

const fieldStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: 12,
  borderBottom: `1px solid ${PALETTE.neutral4}`,
  input: [
    FONTS.p1,
    {
      border: 'none',
      width: '75%'
    }
  ],
  label: [
    FONTS.p2,
    {
      color: PALETTE.neutral65,
      textAlign: 'right'
    }
  ]
});

const fieldMargin = css({
  marginLeft: 48,
  marginRight: 48
});

const createdAtField = css([FONTS.p2]);

const showResendInvitationButton = (data) => {
  return partialQuoteSentOrStarted(data) || partialQuoteWithCashDeposit(data);
};

const partialQuoteSentOrStarted = (data) => {
  return data?.status === 'Sent' || data?.status === 'In Progress';
};

const partialQuoteWithCashDeposit = (data) => {
  return data?.declined === true && data?.cash_deposit_amount_cents != null && data?.status != 'Paid';
};

const ActionWrapper = styled.div([
  {
    display: 'flex',
    gap: 1,
    borderBottom: `1px solid ${PALETTE.neutral4}`,
    marginTop: 16,
    paddingBottom: 40,
    marginBottom: 40
  },
  fieldMargin
]);

const CopiedSpan = styled.span({ color: PALETTE.success100, marginLeft: 12, marginTop: 5 });

const ActionButtonWithMargin = styled(ActionButton)({ marginRight: 5 });

const FormField = (props) => <div css={fieldStyles} {...props} />;

const getCoverageAmountDisplay = (data) => {
  const multiplier = data.coverage_months ? stripTrailingZeroMultiplier(data.coverage_months) : 0;
  const multiDisplay =
    data.monthly_rent === '$0'
      ? `${multiplier} monthly rent`
      : `${data.coverage_months_amount} (${multiplier} monthly rent)`;
  const coverageAmountDisplay = data.coverage_amount !== '$0' ? `${data.coverage_amount}` : multiDisplay;
  return coverageAmountDisplay;
};

const monthlyOrUpfront = (payment_plan_decision) => {
  const options = {
    MONTHLY_ELIGIBLE: 'Monthly or Upfront',
    MONTHLY_INELIGIBLE: 'Upfront Only'
  };

  return options[payment_plan_decision] || 'UNKOWN';
};

const resendTooltipText = () => {
  return 'You no longer need to resend an invitation with Rhino. Simply send this link to your renter and they can fulfill their deposit with Rhino.';
};

const paymentPlanDecision = (data) => {
  const pddLiveMode = (window as any).App?.featureFlags?.ppdLiveMode;

  if (
    data === null ||
    data?.payment_plan_decision === null ||
    data?.payment_plan_decision_version === null ||
    !pddLiveMode
  )
    return null;

  return (
    <React.Fragment>
      <h4 css={[FONTS.h5, fieldMargin, { marginBottom: 8, marginTop: 50 }]}>Quote Details</h4>
      <FormField css={[{ marginTop: 12 }, fieldMargin]}>
        <input
          name="payment plan options"
          value={monthlyOrUpfront(data.payment_plan_decision)}
          id="paymentPlanOptions"
          readOnly
        />
        <label htmlFor="paymentPlanOptions">Payment Plan Options</label>
      </FormField>
      <FormField css={[{ marginTop: 12 }, fieldMargin]}>
        <input
          name="payment plan decision version"
          value={data.payment_plan_decision_version}
          id="paymentPlanDecisionVersion"
          readOnly
        />
        <label htmlFor="paymentPlanDecisionVersion">Payment Plan Decision Version</label>
      </FormField>
      {taktileUnderwriting && (
        <div>
          <FormField css={[{ marginTop: 12 }, fieldMargin]}>
            <input
              name="renter underwriting decision reason"
              value={data.underwriting_reason}
              id="renterUnderwritingDecisionReason"
              readOnly
            />
            <label htmlFor="renterUnderwritingDecisionReason">Renter Underwriting Decision Reason</label>
          </FormField>
          <FormField css={[{ marginTop: 12 }, fieldMargin]}>
            <input
              name="renter underwriting decision version"
              value={data.underwriting_version}
              id="renterUnderwritingDecisionVersion"
              readOnly
            />
            <label htmlFor="renterUnderwritingDecisionVersion">Renter Underwriting Decision Version</label>
          </FormField>
        </div>
      )}
    </React.Fragment>
  );
};

export const InvitationDetails = () => {
  const { invitationId } = useParams();
  const { copy, copied } = useClipboard({ copiedTimeout: 1000 });
  const referrer = useReferrer() || '/admin/invitations';
  const { data: invitationData, isSuccess } = useInvitation(Number(invitationId));
  const segmentUser = useSegmentUser();
  const monthlyRent = invitationData?.monthly_rent === '$0' ? undefined : invitationData?.monthly_rent;
  const [isDeclined, setIsDeclined] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (invitationData?.declined) {
      setIsDeclined(true);
    }
  }, [invitationData]);

  const getFAB = (invitationData) => {
    let copyButtonText, linkToCopy, textOfCopiedLink;

    if (invitationData.use_partner_enrollment) {
      copyButtonText = 'Copy Enrollment Link';
      linkToCopy = invitationData?.enrollment_link;
      textOfCopiedLink = 'Enrollment Link Copied';
    } else {
      copyButtonText = 'Copy Renter Invite';
      linkToCopy = invitationData?.call_to_action_link;
      textOfCopiedLink = 'Renter Invitation Link Copied';
    }

    if (invitationData.can_edit && invitationData.status !== 'Subscribed') {
      return (
        <ActionWrapper>
          <Link to={`${location.pathname}/edit`}>
            {invitationData.editable && (
              <ActionButtonWithMargin id="EditInvitation" variant="noIcon" usage="neutral" disabled={false}>
                Edit
              </ActionButtonWithMargin>
            )}
          </Link>
          <ActionButtonWithMargin
            id="CopyRenterInvite"
            onClick={() => handleInviteLinkSelect(linkToCopy, textOfCopiedLink, invitationData?.id)}
            variant="noIcon"
            usage="neutral"
            disabled={false}
          >
            {copyButtonText}
          </ActionButtonWithMargin>
          {invitationData.use_partner_enrollment && <Tooltip message={resendTooltipText()} icon="question-mark" />}
          {showResendInvitationButton(invitationData) && !invitationData.use_partner_enrollment && (
            <ActionButton
              id="ResendInvitation"
              onClick={() => handleResendInvitationClick(invitationId)}
              variant="noIcon"
              usage="neutral"
              disabled={isDisabled}
            >
              Resend Invitation
            </ActionButton>
          )}
          {copied && <CopiedSpan>Link copied!</CopiedSpan>}
        </ActionWrapper>
      );
    } else {
      return (
        <ActionWrapper>
          <ActionButton
            id="CopyRenterInvite"
            onClick={() => handleInviteLinkSelect(linkToCopy, textOfCopiedLink, invitationData?.id)}
            variant="noIcon"
            usage="neutral"
            disabled={false}
          >
            {copyButtonText}
          </ActionButton>
          {copied && <CopiedSpan>copied!</CopiedSpan>}
        </ActionWrapper>
      );
    }
  };

  const handleInviteLinkSelect = (link: string, eventName: string, invitationId?: number) => {
    copy(link);
    window.analytics.track(eventName, {
      ...segmentUser,
      invitationId
    });
  };

  const handleResendInvitationClick = (data) => {
    resendInvitation(data);
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 10000);
  };

  const partialQuoteStatus = (data) => (data?.declined ? 'Declined for SDI' : data?.status);

  return isSuccess ? (
    <div>
      <div
        css={{
          borderBottom: `1px solid rgb(224, 224, 224)`,
          backgroundColor: PALETTE.neutralLight,
          padding: '16px 24px',
          position: 'sticky',
          top: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Link
            to={referrer}
            css={{ display: 'flex', alignItems: 'center', marginRight: 48 }}
            onClick={() =>
              window.analytics.track('Invitation Details Drawer Closed', {
                ...segmentUser,
                invitationId: invitationData?.id
              })
            }
            aria-label="collapse"
          >
            <MinimizeIcon />
          </Link>
        </div>
        <div css={{ display: 'flex', gap: '4px' }}>
          <p css={createdAtField} data-testid="createdAt">
            Created {invitationData?.created_at}
          </p>
          {invitationData?.guarantor_coverage ? <LegacyTag>Guarantor</LegacyTag> : null}
          <LegacyTag variant={getTagVariant(isDeclined ? 'declined' : invitationData?.status || 'pending')}>
            {partialQuoteStatus(invitationData)}
          </LegacyTag>
        </div>
      </div>
      <div
        css={{
          padding: '16px 0',
          margin: 'auto',
          width: '100%'
        }}
      >
        <h3 css={[FONTS.h3, { marginBottom: 16 }, fieldMargin]}>{invitationData?.tenant_full_name}</h3>
        <div
          css={[
            {
              display: 'flex',
              justifyContent: 'space-between'
            },
            fieldMargin
          ]}
        >
          <p css={FONTS.p1} data-testid="fullAddress">
            {invitationData?.full_address}
          </p>
          <p css={[FONTS.p1, { fontWeight: 'bold' }]}>{invitationData?.unit_name || '-'}</p>
        </div>
        {getFAB(invitationData)}
        <h4 css={[FONTS.h5, fieldMargin, { marginBottom: 8 }]}>Tenant Details</h4>
        <FormField css={[{ marginTop: 12 }, fieldMargin]}>
          <input name="email" value={invitationData?.email} id="email" readOnly />
          <label htmlFor="email">Email</label>
        </FormField>
        <FormField css={[{ marginTop: 12 }, fieldMargin]}>
          <input name="phone number" value={invitationData?.formatted_phone_number} id="phoneNumber" readOnly />
          <label htmlFor="phoneNumber">Phone number</label>
        </FormField>
        {invitationData && invitationData?.use_partner_enrollment && (
          <Fragment>
            <FormField css={[{ marginTop: 12 }, fieldMargin]}>
              <input name="enrollment type" value={'Leasing integration'} id="enrollmentType" readOnly />
              <label htmlFor="enrollmentType">Enrollment Type</label>
            </FormField>
            <FormField css={[{ marginTop: 12 }, fieldMargin]}>
              <input name="enrollment url" value={invitationData?.enrollment_link} id="enrollmentUrl" readOnly />
              <button
                css={{ marginLeft: '5px', background: 'none', border: 'none', cursor: 'pointer' }}
                onClick={() => copy(invitationData?.enrollment_link)}
                aria-label="Copy Enrollment URL"
              >
                <LinkIcon />
              </button>
              <label htmlFor="enrollmentUrl">Enrollment URL</label>
            </FormField>
          </Fragment>
        )}
        <h4 css={[FONTS.h5, fieldMargin, { marginTop: 48, marginBottom: 8 }]}>Invitation Type</h4>
        <FormField css={[{ marginTop: 12 }, fieldMargin]}>
          <input name="invitationType" value={invitationData?.type} id="invitationType" readOnly />
        </FormField>
        <h4 css={[FONTS.h5, fieldMargin, { marginTop: 48, marginBottom: 8 }]}>Lease Details</h4>
        <FormField css={[{ marginTop: 24 }, fieldMargin]}>
          <input name="lease dates" value={invitationData?.coverage_dates} id="leaseDates" readOnly />
          <label htmlFor="leaseDates">Lease dates</label>
        </FormField>
        <FormField css={[{ marginTop: 12 }, fieldMargin]}>
          <input name="monthly rent" value={monthlyRent} id="monthlyRent" readOnly />
          <label htmlFor="monthlyRent">Monthly rent</label>
        </FormField>

        <h4 css={[FONTS.h5, fieldMargin, { marginBottom: 8, marginTop: 50 }]}>Deposit Options</h4>
        {invitationData && invitationData?.cash_deposit_only === false && (
          <FormField css={[{ marginTop: 12 }, fieldMargin]}>
            <input
              name="coverage amount"
              value={getCoverageAmountDisplay(invitationData)}
              id="coverageAmount"
              readOnly
            />
            <label htmlFor="coverageAmount">Coverage amount</label>
          </FormField>
        )}
        {invitationData && invitationData?.cash_deposit_amount !== '$0' && (
          <FormField css={[{ marginTop: 12 }, fieldMargin]}>
            <input
              name="cash deposit amount"
              value={invitationData?.cash_deposit_amount}
              id="cashDepositAmount"
              readOnly
              css={{ width: '245px !important' }}
            />
            <label htmlFor="cashDepositAmount">Cash Deposit amount</label>
          </FormField>
        )}
        {paymentPlanDecision(invitationData)}
      </div>
    </div>
  ) : (
    <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Loading />
    </div>
  );
};

export default InvitationDetails;
