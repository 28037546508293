import { useRef } from 'react';

/**
 * Hook to fetch the feature flags from the window
 * @param void
 * @returns Returns featureFlags
 */

const getFeatureFlags = () => {
  return window?.App?.featureFlags || {};
};

interface FeatureFlags {
  manageCarrierStatus?: boolean;
  purchasedRentersInsuranceDetails?: boolean;
  singlePropertyUpload?: boolean;
  syncProspectsEnabled?: boolean;
  lemonadeInRLIEnrollment?: boolean;
}

interface UseFeatureFlags {
  getFreshFeatureFlags: () => FeatureFlags;
  featureFlags: FeatureFlags;
}

export const useFeatureFlags = (): UseFeatureFlags => {
  const featureFlags = useRef<Record<any, any>>(getFeatureFlags());
  const getFreshFeatureFlags = () => {
    featureFlags.current = getFeatureFlags();
    return featureFlags.current;
  };
  return { featureFlags: featureFlags.current, getFreshFeatureFlags };
};

export default useFeatureFlags;
