/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { PALETTE, FONTS, Loading, PlusIcon } from '@sayrhino/rhino-shared-js';
import useInvitations from 'api/v2/useInvitations';
import { flatMap } from 'lodash';
import React from 'react';
import { useQueryClient } from 'react-query';
import { NavLink as Link, useLocation, useMatch } from 'react-router-dom';
import {
  getInvitationPropertyFilterOptions,
  invitationPropertyFilterOptionsKey
} from '../../../api/v2/useInvitationPropertyFilterOptions';
import {
  getInvitationStaticFilterOptions,
  invitationStaticFilterOptionsKey
} from '../../../api/v2/useInvitationStaticFilterOptions';
import useIntersectionObserver from '../../../hooks/v2/useIntersectionObserver';
import CardLink from './CardLink';
import FilterLink from './Filters/FilterLink';
import { useFilterContext } from './utils/filters';
import InvitationCard, { convertInvitationUpdateToCardProps } from './InvitationCard';
import { urlWithReferrer } from './utils';
import { useCanSendInvite, useUserRole } from './utils/userRole';
import { useSegmentTrackOnLoad } from './utils/segmentTracker';

const buttonLinkCSS = css({
  display: 'flex',
  alignItems: 'center',
  height: '48px',
  padding: '0 24px',
  borderRadius: '24px',
  fontFamily: 'MaisonNeueExtendedMedium',
  fontSize: '16px',
  lineHeight: '28px',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  color: PALETTE.neutralDark,
  border: '1px solid',
  borderColor: PALETTE.neutralDark,
  '&:hover': {
    backgroundColor: PALETTE.neutralDark,
    color: PALETTE.neutralLight
  },
  '&:disabled': {
    color: PALETTE.neutral25,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'not-allowed'
  }
});

const filtersPath = '/admin/invitations/filters';
const invitationsPath = '/admin/invitations';

const prefetchFilterOptions = (debouncedFilters) => {
  const cache = useQueryClient();
  const params = { filter: { states: debouncedFilters.states }, page: 1 };

  cache.prefetchQuery([invitationPropertyFilterOptionsKey, params], () => getInvitationPropertyFilterOptions(params));
  cache.prefetchQuery([invitationStaticFilterOptionsKey], getInvitationStaticFilterOptions);
};

export const InvitationList = () => {
  const { debouncedFilters, filtersCount } = useFilterContext();
  const queryCache = useQueryClient();
  // callback that removes old query group results that don't match the new filters
  const onSuccess = () => queryCache.removeQueries(['invitations'], { exact: true });
  const invitationsResponse = useInvitations(debouncedFilters, onSuccess);
  const { hasNextPage, fetchNextPage, isSuccess, isLoading, isFetchingNextPage } = invitationsResponse;
  const data = invitationsResponse.data?.pages;
  const loadMoreRef = React.useRef<HTMLDivElement>(null);

  useIntersectionObserver({
    root: null,
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage
  });

  prefetchFilterOptions(debouncedFilters);
  const invitationsData = flatMap(data, (d) => d.data || []);
  const hasInvitations = isSuccess && invitationsData.length > 0;
  const filtersActive = Boolean(useMatch(filtersPath));
  const location = useLocation();
  const invitations =
    isSuccess &&
    invitationsData.map((invitation) => {
      return (
        <CardLink
          key={`${invitation.type}-${invitation.id}`}
          to={urlWithReferrer(`/admin/invitations/${invitation.id}`, location.pathname)}
        >
          <InvitationCard
            key={`${invitation.type}-${invitation.id}`}
            {...convertInvitationUpdateToCardProps(invitation)}
          />
        </CardLink>
      );
    });
  const empty = isSuccess && (
    <div>
      <h6 css={[FONTS.h3]}>No Invitations to Show</h6>
    </div>
  );
  const loadMoreDiv = isFetchingNextPage && <Loading />;
  const nothingMoreDiv = invitations.length > 1 && 'Nothing more to load';
  const loading = (
    <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Loading />
    </div>
  );
  const { isAdmin, isPropertyManager, isPropertyManagerAdmin, isListingAgent } = useUserRole();
  const canSendInvites = useCanSendInvite();
  const canSendInvitation =
    (isAdmin || isPropertyManager || isPropertyManagerAdmin || isListingAgent) && canSendInvites;

  useSegmentTrackOnLoad('Invitations Viewed');

  return (
    <div
      style={{
        maxWidth: '480px',
        margin: 'auto',
        paddingTop: '1.875rem'
      }}
    >
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '1.875rem' }}
      >
        <h2 css={[FONTS.h3]}>Invitations</h2>
        {canSendInvitation && (
          <Link to="/admin/invitations/new" css={[buttonLinkCSS]}>
            Send invitation <PlusIcon css={{ paddingLeft: 10 }} />
          </Link>
        )}
      </div>
      <div css={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end', marginBottom: '1.5rem' }}>
        <FilterLink
          filtersActive={filtersActive}
          filtersCount={filtersCount}
          id="invitations-filter-button"
          to={urlWithReferrer(filtersActive ? invitationsPath : filtersPath, location.pathname)}
        />
      </div>
      {hasInvitations ? invitations : empty}
      {isLoading ? loading : null}
      <div
        ref={loadMoreRef}
        css={[
          FONTS.h4,
          {
            display: hasInvitations ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '18px'
          }
        ]}
      >
        {hasNextPage ? loadMoreDiv : nothingMoreDiv}
      </div>
    </div>
  );
};

export default InvitationList;
